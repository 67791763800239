/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

$(document).ready(function () {
    $(document).on('change click', '.js-on-click-submit', function(event){
        $(event.target).closest('form').submit();
    });

    $(document).on('change click', 'input[name="condition"][type="radio"]', function(event){
        $(event.target).closest('form').submit();
    });

    $(document).on('change click', '#otherBrandButton', function () {
        $('.select-brand').hide();
        $('.provide-brand').show();
        $('#OtherBrandName').focus();
    });

    // Submit data as they go
    $("input[autosave], textarea[autosave], select[autosave]").on('change', function(e){
        // console.log([this.name, this.value]);
        axios.post('/sell/save', {
            [this.name]: this.value 
        })
        .then(response => {
            console.log(response.data);
        })
        .catch(error =>{
            console.log(error);
        });
    });

    /* Start of Sell Form Version B */

    

    $("#instrumentInput").parent().append('<input class="form-control" id="otherInstrument" name="other_instrument" style="margin-top: 5px; display: none;"/>');
    $(document).on('change', '#instrumentInput', function(){
        $("#otherBrand").hide();
        $("#otherInstrument").hide();
        
        loadBrands();

        if($(this).val() == 'Other'){
            $("#otherInstrument").show();
            $("#brandInput").val('Other');
            $("#otherBrand").show();
        }
    });

    $("#brandInput").parent().append('<input class="form-control" id="otherBrand" name="other_brand" style="margin-top: 5px; display: none;"/>');
    $(document).on('change', '#brandInput', function(){
        if($(this).val() == 'Other'){
            $("#otherBrand").show();
        }else{
            $("#otherBrand").hide();
        }
    });

    $("#sellFormVersionB").on('submit', function(e){
        console.log("did submit");
        var isValid = true;
        if(
            $("#instrumentInput").val() == '' 
            || ($("#instrumentInput").val() == 'Other' && $("#otherInstrument").val() == "")
        ){
            $("#instrumentInput")[0].setCustomValidity("Please choose an instrument type");
            isValid = false;
        }else{
            $("#instrumentInput")[0].setCustomValidity("");
        }

        if(
            $("#brandInput").val() == '' 
            || ($("#brandInput").val() == 'Other' && $("#otherBrand").val() == "")
        ){
            $("#brandInput")[0].setCustomValidity("Please choose a brand");
            isValid = false;
        }else{
            $("#brandInput")[0].setCustomValidity("");
        }

        if(!isValid){
            // Prevent submit
            e.preventDefault();
        }
        
    });
    
    $("#instrumentInput").on('input', function(event){
        this.setCustomValidity("");
    });
    $("#otherInstrument").on('input', function(event){
        $("#instrumentInput")[0].setCustomValidity("");
    });
    $("#brandInput").on('input', function(event){
        this.setCustomValidity("");
    });
    $("#otherBrand").on('input', function(event){
        $("#brandInput")[0].setCustomValidity("");
    });
    
    function loadBrands(){
        var b = $("#brandInput").empty();
        b.append($('<option></option>').attr('value', '').text(''));
        if($("#instrumentInput").val() in brands){
            brands[$("#instrumentInput").val()].forEach(function(value){
                b.append($('<option></option>').attr('value', value['name']).text(value['display']));
            });
        }
        b.append($('<option></option>').attr('value', 'Other').text('Other'));
        $("#otherBrand").hide();
    }
    
    if($("#instrumentInput").length){
        if($("#instrumentInput option[value='" + php_instrument + "']").length == 0){
            $("#instrumentInput").val('Other');
            $("#otherInstrument").val(php_instrument);
            $("#otherInstrument").show();
        }
        
        loadBrands();
        if($("#brandInput option[value='" + php_brand + "']").length){
            $("#brandInput").val(php_brand);
        }else{
            $("#brandInput").val('Other');
            $("#otherBrand").val(php_brand);
            $("#otherBrand").show();
        }
        
        // $("#instrumentInput").trigger("change");
        // $("#brandInput").trigger("change");
    }
    
    /* End of Sell Form Version B */

    var currentPhoto = 1;
    $("#photoSection").on("change", ".js-add-more-photos", function(e){
        if($("#ProductPhotos" + currentPhoto).val()){
            currentPhoto += 1;
            var newInput =
            '<div class="col-md-12 pt-3">' +
                '<input type="file" name="photo[]" id="ProductPhotos' + currentPhoto + '" accept="image/*" style="position: static" class="js-add-more-photos">' + 
            '</div>';
            $("#photoSection").append(newInput);
        }
    });

    $(".js-need-address").on('invalid', function(event){
        this.setCustomValidity("Needed to send you your FREE sellers packet");
    });
    
    $(".js-need-address").on('input', function(event){
        this.setCustomValidity("");
    });


});